







import { Vue, Component } from 'vue-property-decorator';
import { FrontE } from '@/sdk/common/SysConst';
@Component
export default class InsBuilding extends Vue {
    private img:string = '';
    private background:string = '';
    private paddingTop:string = '';
    beforeCreate () {
      if ((Vue.prototype.userAgent === 'mobile' && !FrontE.mobileBuilding) || (Vue.prototype.userAgent === 'pc' && !FrontE.pcBuilding)) {
        this.$router.push('/');
      }
    }
    created () {
      if ((Vue.prototype.userAgent === 'mobile' && !FrontE.mobileBuilding) || (Vue.prototype.userAgent === 'pc' && !FrontE.pcBuilding)) return;
      this.background = this.userAgent === 'mobile' ? '/static/building/Mobile.jpg' : '/static/building/PC.jpg';
      this.img = this.userAgent === 'mobile' ? '/static/building/MobileLogo.jpg' : '/static/building/PCLogo.jpg';
      this.$HiddenLayer();
    }
    mounted () {
    }
    data () {
      return {
      };
    }
    get indexLayout () {
      return '';
    }
}
